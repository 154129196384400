@import "constants/constants.scss";

.form {
    .form__paper {
        margin: 32px 0;
        padding: 16px;
        .form__title {
            font-size: 22px;
            text-align: center;
        }
        .form__avatar {
            width: 80px;
            height: 80px;
            margin: 30px auto;
        }
        input[type="checkbox"] {
            display: inline;
            width: 25px;
            height: 25px;
            margin-right: 15px;
        }
        .form__checkbox {
            cursor: pointer;
            width: 150px;
            margin-left: 50px;
        }
    }

    .form__subtitle {
        text-align: center;
    }
    .form__submit_button {
        display: block;
        margin: 20px auto;
        font-size: 1rem;
    }
    .form__sign_button {
        display: block;
        text-align: center;
        margin: 20px auto;
        font-size: 1rem;
    }
    
    .form__return_button {
        display: block;
        text-align: center;
        margin: 20px auto;
        font-size: 1rem;
        color: $color_lightGrey;
    }
}
