.info {
    .info__paper {
        margin-bottom: 20px;
        padding: 16px;
        .info__name {
            text-align: center;
            font-size: 1.5rem;
            margin-bottom: 30px;
        }
    }
    .info__delete {
        display: block;
        margin: 40px auto;
    }
}