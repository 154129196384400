@import "constants/constants.scss";

.menuGroup {
    .menuGroup__title {
        font-size: 1.8rem;
        text-align: center;
        margin: 30px 0;
    }
    .form {
        padding: 20px;
        .form__error {
            color: #ff0000;
            font-size: 0.8rem;
        }
        .buttons {
            display: flex;
            justify-content: center;
            margin: 30px 0 0;
            .button__delete {
                color: #ff0000;
            }
            .button__cancel {
                margin: 0 16px;
                color: $color_lightGrey;
            }
        }
    }
}
