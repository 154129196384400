@import "constants/constants.scss";

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 720px;
    max-height: 90%;
    overflow-y: auto;
    background-color: #fff;
    border: 2px solid $color_lightGrey;
    border-radius: 10px;
    // box-shadow: 10px 5px 5px black;
    padding: 24px;
    @media (max-width: 800px) {
        width: 400px;
    }
    @media (max-width: 450px) {
        width: 90%;
    }
    .modal__close {
        position: absolute;
        left: 18px;
        top: 8px;
        font-weight: 700;
        font-size: 30px;
        color: #ff0000;
        cursor: pointer;
    }
    .modal__box {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        justify-content: space-between;
        @media (max-width: 450px) {
            img {
                width: 100%;
            }
        }
        .modal__textBlock {
            padding: 20px;
            height: 300px;
            width: 300px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media (max-width: 800px) {
                padding: 20px 0;
            }
            .card__price {
                font-size: 1.3rem;
                font-weight: 700;
            }
        }
    }
    .card__description {
        color: $color_lightGrey;
        margin: 16px 0;
    }
    .card__detailText {
        color: $color_lightGrey;
    }
}
