@import "constants/constants.scss";

.passwordForm {
    padding: 16px;
    .passwordForm__submit_button {
        display: block;
        margin: 0 auto;
    }
    .passwordForm__buttons {
        text-align: center;
        .passwordForm__cancel {
            color: $color_lightGrey;
        }
    }
}
