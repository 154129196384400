@import "constants/constants.scss";

.basketIcon {
    position: fixed;
    right: 14px;
    bottom: 50%;
    transform: translate(0, 50%);
    z-index: 5;
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 10px;
    background-color: #fff;
    cursor: pointer;
    .basketIcon__icon {
        font-size: 50px;
        color: $color_grey;
    }
}
