.about {
    .about__title {
        padding: 40px 0 20px 0;
        font-size: 45px;
        text-align: center;
        font-weight: 500;
        text-transform: uppercase;
    }
    .about__box {
        margin: 0 auto;
        .about__slogan {
            padding: 40px 0 10px 0;
            font-size: 30px;
            text-align: center;
            font-weight: 700;
        }
        .about__descr {
            margin: 20px 0;
            font-size: 18px;
            font-weight: 300;
            text-align: justify;
        }
        .about__list {
            margin: 0 0 15px 30px;
            display: flex;
            
        }
        .about__item {
            font-size: 18px;
            font-weight: 300;
            padding-left: 15px;
        }
        @media (max-width: 768px) {
            .about__item div span {
                font-size: 16px;
            }
        }
    }
    @media (max-width: 768px) {
        .about__title {
            font-size: 30px;
        }
    }
}
