$color_mint: #00a1b6;
$color_grey: #333333;
$color_brown: #683b39;
$color_lightGrey: #757575;

@mixin button {
    margin: 0 auto;
    border-radius: 15px;
    font-weight: 500;
    transition: 0.5s all;
    cursor: pointer;
}
