.field {
    // margin: 0 30px 30px;
    display: flex;
    flex-direction: column;
    label {
        margin-bottom: 10px;
    }
    .field__control {
        margin-bottom: 20px;
        .field__phone {
            width: 100%;
            height: 56px;
        }
        .field__helper {
            color: #ff0000;
        }
    }
}
