@import "constants/constants.scss";

.card {
    max-width: 320px;
    margin: 15px 0;
    @media (min-width: 1250px) {
        margin: 20px;
    }
    .card__content {
        text-align: left;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .card__price {
        font-size: 1.3rem;
        margin-bottom: 8px;
        font-weight: 700;
    }
    .card__description {
        font-size: 14px;
        color: $color_lightGrey;
        margin-bottom: 8px;
    }
    .card__boxItems p {
        font-size: 14px;
    }
    .card__buttons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
        .button__detail {
            @include button;
            padding: 8px;
            margin: 0 8px;
            width: 120px;
            border: 1px solid $color_mint;
            white-space: nowrap;
            &:hover {
                color: #fff;
                background-color: $color_mint;
            }
        }
        .button__basket {
            @include button;
            padding: 8px;
            width: 120px;
            color: #fff;
            border: 1px solid $color_mint;
            background-color: $color_mint;
            white-space: nowrap;
            &:hover {
                color: $color_mint;
                background-color: #fff;
            }
        }
    }
}
