@import 'constants/constants.scss';

.footer_block {
    min-height: 230px;
    background-color: $color_grey;
    text-align: center;
    color: #fff;
    .footer_item {
        padding: 10px;
        a {
            color: #fff;
            text-decoration: none;
        }
        &:hover {
            text-decoration: underline;
        }        
    }
    .footer_icon {
        font-size: 50px;
        margin: 35px 15px 10px 15px;
        color: #fff;
        &:hover {
            transform: translateY(-3px);
        }
    }
}