@import "constants/constants.scss";

.return {
    .return__link {
        @include button;
        display: block;        
        border: 1px solid $color_mint;
        background-color: $color_mint;        
        padding: 10px;
        max-width: 180px;
        margin-bottom: 50px;
        text-align: center;
        text-transform: uppercase;
        color: white;        
        &:hover {
            color: black;
            background-color: #fff;
        }
        @media (max-width: 768px) {
            font-size: 14px;
            max-width: 140px;
        }
    }
}
