@import "constants/constants.scss";

@mixin button {
    display: inline;    
    border-radius: 15px;
    padding: 4px 12px 2px;
    margin: 0 12px;   
    cursor: pointer;
}

.menu {
    text-align: center;
    .menu__image {
        max-width: 80%;
        margin-top: 80px;
        cursor: pointer;
    }
    .menu__title {
        margin-top: 30px;
        font-size: 40px;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 30px;
        } 
    }
    .menu__subtitle {
        margin: 20px 0 30px 0;
        font-size: 25px;
        font-weight: 300;
        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
    .menu__langButton {
        @include button;
        border: 1px solid $color_lightGrey;    
        color: $color_lightGrey;    
    }
    
    .menu__langButtonActive {
        @include button;
        background-color: $color_mint;
        border: 1px solid $color_mint;    
        color: #fff;    
    }
}
