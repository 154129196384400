@import "constants/constants.scss";

.error {
    .error__image {
        display: block;
        margin: 100px auto;
    }
    .error__title {
        text-align: center;
        font-size: 20px;
        color: $color_mint;
    }
    .error__link {
        display: block;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        margin-top: 30px;
        margin-bottom: 100px;
        color: $color_mint;
    }
}
