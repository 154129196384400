@import "constants/constants.scss";

.basketForm {
    .basketForm__label {
        font-size: 18px;
        font-weight: 500;
        white-space: normal;
        color: #000;
        margin: 15px 0;
    }
    .basketForm__error {
        color: #ff0000;
        font-size: 14px;
    }
    .basketForm__submitButton {
        @include button;
        display: block;
        margin: 0 auto;
        margin-top: 32px;
        padding: 10px;
        width: 220px;
        color: #fff;
        border: 1px solid $color_mint;
        background-color: $color_mint;
        &:hover {
            color: #000;
            background-color: #fff;
        }
    }
    .phone {
        width: 80%;
    }
}
