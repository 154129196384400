@import "constants/constants.scss";

@mixin langButton {
    display: inline-block;
    border-radius: 15px;
    padding: 4px 12px 2px;
    margin: 30px 6px;
    cursor: pointer;
}
.drawer {
    .drawer__menuButton {
        position: fixed;
        right: 20px;
        top: 15px;
        z-index: 5;
        .drawer__menuIcon {
            font-size: 50px;
            font-weight: 700;
            padding: 8px;
            color: $color_lightGrey;
            border: 2px solid $color_lightGrey;
            border-radius: 50%;
            cursor: pointer;
        }
    }
}
.drawer__box {
    width: 250px;
    img {
        padding-bottom: 20px;
    }
    .drawer__admin {
        padding: 0 28px 14px;
        font-size: 20px;
        cursor: pointer;
        &:hover {
            color: $color_lightGrey;
        }
    }
    .drawer__loginBox {
        display: flex;
        align-items: center;
        .drawer__login {
            padding: 14px 28px;
            font-size: 20px;
            max-width: 250px;
            cursor: pointer;
            &:hover {
                color: $color_lightGrey;
            }
        }
        .drawer__logout {
            svg {
                margin-top: 6px;
                cursor: pointer;
                &:hover {
                    color: $color_lightGrey;
                }
            }
        }
    }
    .drawer__items {
        padding: 20px;
        font-size: 20px;
        .drawer__item {
            color: #000;
            text-decoration: none;
            padding: 8px;
            &:hover {
                color: $color_lightGrey;
            }
        }
    }
    img {
        margin: 30px 0 0 20px;
    }
    .lang__box {
        text-align: center;
        .lang__button {
            @include langButton;
            border: 1px solid $color_lightGrey;
            color: $color_lightGrey;
        }
        .lang__button_active {
            @include langButton;
            background-color: $color_mint;
            border: 1px solid $color_mint;
            color: #fff;
        }
    }
}
