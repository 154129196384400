@import "constants/constants.scss";

.admin {
    text-align: center;
    .admin__title {
        margin: 20px 0;
        font-size: 1.8rem;
    }
    .admin__return {
        color: $color_lightGrey;
        margin-bottom: 20px;
    }
    .admin__buttons {
        margin-bottom: 20px;
        .admin__add {
            margin: 10px;
        }
        .admin__button {
            margin: 10px;
            border-radius: 5px;
        }
    }
}
